import React, { Component } from 'react';
import { Link } from "react-router-dom";
import classNames from "classnames";
import 'react-perfect-scrollbar/dist/css/styles.css';

import { SettingsContext } from "../../../../../Magazine/MagazineSettings";
import { GET_CURRENT_NUMERO, GET_MENU_NUMEROS } from "../../../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import { ScrollContext } from "../../../../../Scroll/Scroll";

let PerfectScrollbar;

class NumeroMenu extends Component {

    constructor(props){
        super(props);

        this.state = {
            menuShow: false,
            isPerfect: false
        }

    }

    toggleNumeroMenu = (toggle = null, numero = null) => {

        if(toggle === false)
            this.setState(() => {
                return {
                    menuShow: false
                }
            });
        else {
            this.setState((prevState) => {
                return {
                    menuShow: !prevState.menuShow
                }
            })
        }
    };

    componentDidMount() {
        // if(typeof window !== "undefined"){
        //     let react_perfect_scrollbar = require('react-perfect-scrollbar');
        //     PerfectScrollbar = react_perfect_scrollbar.default;
        //     this.setState({
        //         isPerfect: true
        //     });
        // }
    }

    fetchMore(){

        this.props.fetchMore({
            variables: {
                offset: this.props.numeros.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {

                if (!fetchMoreResult) return prev;

                return Object.assign({}, prev, {
                    numeros: [...prev.numeros, ...fetchMoreResult.numeros]
                });

            }
        });

    }

    render() {

        let length = this.props.numeros.length;

        const classes = classNames({
            'menu-numero': true,
            'menuShow': this.state.menuShow
        });

        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (
                    <Query query={ GET_MENU_NUMEROS } variables={{
                        magazine_slug: this.context.magazine.slug,
                    }} fetchPolicy="cache-only">
                        {({loading, error, data, updateQuery, client, networkStatus}) => {
                            let numeros = data.datas.menuNumeros;

                            const classes2 = classNames({
                                'numeros-menu': true,
                                'show-more': (numeros.length > 3)
                            });

                            return(
                                <Query query={ GET_CURRENT_NUMERO }>
                                    {({loading, error, data, client}) => {

                                        let current = data.currentNumero;

                                        return null;

                                        /*return (
                                            <div className={ classes } data-text="true">
                                                <button className="menu-numeros-btn"
                                                        style={ fonts.family1 } onClick={ this.toggleNumeroMenu }>
                                                    <span>Tous les numéros</span>
                                                    <i className="icon-chevron_down" />
                                                </button>

                                                {this.state.menuShow && <div className="numeros-menu-outer" style={ fonts.family4 }>
                                                    {!this.state.isPerfect &&
                                                        <div className="inner-numero-menu">
                                                        <ul className={ classes2 }>
                                                            { numeros.map((numero) => {

                                                                if(numero._id === current._id){
                                                                    return (
                                                                        <li className="current" key={numero._id}>
                                                                            <Link to={`/${numero.slug}`} onClick={() => { this.props.toggleMenu(false, numero) } } style={ fonts.family3 }>
                                                                                {numero.title} <i className="icon-check" />
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }

                                                                return (
                                                                    <li key={numero._id}>
                                                                        <Link to={`/${numero.slug}`} onClick={() => { this.props.toggleMenu(false, numero) } }>
                                                                            {numero.title}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }) }
                                                        </ul>
                                                    </div>
                                                    }
                                                </div>}
                                            </div>
                                        )*/
                                    }}
                                </Query>
                            );
                        }}
                    </Query>
                )}
            </SettingsContext.Consumer>
        )
    }



}

NumeroMenu.contextTypes = {
    magazine: PropTypes.object,
    resetCache: PropTypes.bool
};

const HeaderView = ({ updateWidthScroll, menu, match, toggleMenu, data,
                        scrolled, scrollFX, fetchMore, magazine, currentNumero, logo, fonts, isPage, openLink }) => {

    const classes = classNames({
        'scrolled': scrolled || isPage,
        'header': true,
        'isPage': isPage,
        'menuShow': menu
    });

    if(typeof window !== "undefined") {
        if (menu) {
            requestAnimationFrame(() => {
                document.body.style.overflowY = "hidden";
            });
        } else {
            requestAnimationFrame(() => {
                document.body.style.overflowY = "initial";
            });
        }
    }

    return (
        <header className={ classes }>
            {/*!menu && !scrolled && !isPage && <div className="fx-backgrd" data-text="true" />*/}
            <div className="inner">

                {!logo.horizontal &&
                    <h1 style={ fonts.family1 }>
                        <Link data-text="true"
                              to={`/${currentNumero.slug}`}
                              onClick={ (e) => openLink(`/${currentNumero.slug}` , e) }
                        >
                            {logo.carre &&
                                <img src={logo.carre} alt=""/>
                            }
                            <div dangerouslySetInnerHTML={{ __html: magazine.title }} />
                        </Link>
                    </h1>
                }
                {logo.horizontal &&
                    <h1>
                        <Link to={`/${currentNumero.slug}`} onClick={ (e) => openLink(`/${currentNumero.slug}` , e) }>
                            <img src={ logo.horizontal } alt=""/>
                        </Link>
                    </h1>
                }

                {data.numeros &&
                    <NumeroMenu match={ match } toggleMenu={ toggleMenu }
                                numeros={ data.numeros }
                                fetchMore={ fetchMore }/>
                }

            </div>
            {scrollFX &&
                <div className="scroll-block">
                    <div/>
                    <div className="scroll-inner">
                        <ScrollContext.Consumer>
                            {({ changeScrollWidth, scrollWidth }) => (
                                <ScrollFX updateWidthScroll={ changeScrollWidth } scrollWidth={ scrollWidth } scrollFX={ scrollFX }/>
                            )}
                        </ScrollContext.Consumer>
                    </div>
                </div>
            }
        </header>
    )
};

class ScrollFX extends Component {

    constructor(props){
        super(props);

        this.ref = React.createRef();

        this.state = {
            width: "",
            body: document.body,
            html: document.documentElement,
            main: document.getElementsByClassName('inner-main')
        };

        this.handleScroll = this.handleScroll.bind(this);

    }

    handle = () => { let that = this;
        if(typeof window !== "undefined"){
            requestAnimationFrame(that.handleScroll);
        }
    };

    componentDidMount() { let that = this;
        window.addEventListener('scroll', that.handle, false);
    }

    componentWillUnmount() { let that = this;
        window.removeEventListener("scroll", that.handleScroll, false);
    }

    // setArticleRead(article)

    handleScroll() {

        if(this.props.scrollFX) {

            // header article = 539px
            let percentage = 1 - (950 / window.innerHeight);
            let sbHeight = window.innerHeight * (window.innerHeight / document.body.offsetHeight);

            let height = Math.max(this.state.body.scrollHeight, this.state.body.offsetHeight,
                this.state.html.clientHeight, this.state.html.scrollHeight, this.state.html.offsetHeight);

            let widthCalcul = (((window.scrollY + window.innerHeight) - 1280) / (height - 1280) * 100);

            this.props.updateWidthScroll(widthCalcul);

            // // this.setState({width: ((window.scrollY + window.innerHeight) / (height - 539) * 100) - percentage * 100 + 30    })
            // this.setState({width: widthCalcul  })

        }
    }

    render() {

        const style = {
            width: this.props.scrollWidth + "%"
        };

        return(
            <div className="scroll-fx" style={ style }  ref={ this.ref } />
        )
    }

}

export default HeaderView;
