import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "./Header";
import MainView from "./views/MainView";
import Menu from "../../Menu/Menu";

import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

class Main extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
        }
    }

    isPreview = () => {
        return this.props.location.search;
    };

    render() {
        const { match, previewLoading } = this.props;
        return (
            <div className="main">
                <Header match={ match } toggleMenu={ this.props.toggleMenu } menuShow={ this.props.menuShow }/>
                <MainView match={ match } show={ this.props.menuShow } toggleMenu={ this.props.toggleMenu } isPreview={ this.isPreview } previewLoading={ previewLoading }/>
                <Menu show={ this.props.menuShow } toggleMenu={ this.props.toggleMenu }/>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        previewLoading: state.preview.previewLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Main)));

