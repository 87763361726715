import React, { Component } from 'react';

import Text from "./components/Text";
import Title from "./components/Title";
import Citation from "./components/Citation";
import Carrousel from "./components/Carrousel";
import Image from "./components/Image";
import Chiffres from "./components/Chiffres";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import BlockChapitre from "./BlockChapitre";
import Loadable from "react-loadable";
import Text2 from "./components/Text2";

import "./style/slick.scss";
import "./style/lightbox.scss";

import "../style/components.scss";

const RightSidebarComponent = Loadable({
    loader: () => import('./RightSidebar'),
    loading: () => { return null },
});

export class Content extends Component {

    render() { const { article, match, isPage } = this.props;
        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (
                    <div className="content" style={ fonts.family4 }>
                        {!isPage && <BlockChapitre match={ match } fonts={ fonts } />}
                        <div className="global-content">

                            <div data-text="true">
                                {article.content && article.content.map((content, index)=> {
                                    switch (content.kind) {
                                        case 'title':
                                            return( <Title datas={ content } fonts={ fonts } key={ index } /> );
                                        case 'text':
                                            return( <Text datas={ content } fonts={ fonts } key={ index }/> );
                                        case 'citation':
                                            return( <Citation datas={ content } fonts={ fonts } key={ index }/> );
                                        case 'slider':
                                            return( <Carrousel datas={ content } fonts={ fonts } key={ index }/> );
                                        case 'images':
                                            return( <Image datas={ content } fonts={ fonts } key={ index }/> );
                                        case 'chiffres':
                                            return( <Chiffres datas={ content } fonts={ fonts } key={ index }/> );
                                        default:
                                            return null;
                                    }
                                })}
                            </div>

                        </div>
                        {!isPage && <RightSidebarComponent article={ article }/>}
                    </div>
                )}
            </SettingsContext.Consumer>
        )
    }

}
