import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArticleView from "./views/ArticleView";
import { Query } from "react-apollo";
import { GET_CURRENT_NUMERO } from "../../../../Queries";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import animations from "./animations";

import { ScrollContext } from "../../../Scroll/Scroll";

class Article extends Component {

    constructor(props){
        super(props);

        this.state = {
            scrolled: false,
            heightFrame: "539px",
            toggleRead: false,
            currentArticle: null
        };

        this.handleScroll = this.handleScroll.bind(this);

    }

    componentDidMount() {
        this.setHeightCouverture();

        if(this.ref){
            animations.ArticleEnter(this.ref);
        }

        window.addEventListener('scroll', this.handleScroll);
        window.scrollTo(0, 0);

    }

    componentWillUnmount() {

        if(this.ref){
            animations.ArticleLeave(this.ref);
        }

        window.removeEventListener('scroll', this.handleScroll, false);
    }

    changeCurrentArticle(article, numero){

        if(!article) return null;

        if(this.state.currentArticle) {
            if (article._id !== this.state.currentArticle._id) {
                this.setState({
                    currentArticle: article
                });
                this.props.setArticleLastRead(article, numero);
            }
        } else {
            this.setState({
                currentArticle: article
            });
            this.props.setArticleLastRead(article, numero);
        }
    }

    setHeightCouverture() {

        if(window.innerWidth <= 425){

            /*let heightCouverture = require('ios-inner-height');

            if(heightCouverture() !== window.innerHeight) {
                this.setState({ heightFrame: window.innerHeight - 80 + "px"})
            } else {
                //this.setState({ heightFrame: window.innerHeight + "px"})
                //this.setState({ heightFrame: heightCouverture() + "px"})
            }*/

        }

    }

    handleScroll() {

        if( window.scrollY >= 100){
            if(!this.state.scrolled)
                this.setState({ scrolled: true })
        } else {
            if(this.state.scrolled)
                this.setState({ scrolled: false })
        }

    }

    setReference = (ref) => {
        this.ref = ref;
    };

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.slug !== this.props.slug) {
            this.setState({
                toggleRead: false,
            });
            window.scrollTo(0, 0);
        }
    }

    getArticle(articles, numero) {

        let article = articles.filter((article) => article.slug === this.props.slug)[0];

        if(!article) {
            this.props.history.push("/" + numero.slug);
            return null;
        }

        this.changeCurrentArticle(article, numero);

        return article;
    }

    isPreview = () => {
        return this.props.location.search;
    };

    getArticleWithQuery(articles, numero) {

        let article = articles.filter((article) => article.slug === this.props.slug)[0];

        if(!article) {
            this.props.history.push("/" + numero.slug);
            return null;
        }

        return article;

    }

    articleIsRead = (user, article) => {
        if(user.articles) {
            return user.articles.some((articleObj) => {
                return (articleObj._id === article._id && articleObj.readed);
            });
        }
    };

    render() { let that = this;

        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(loading) return "LOADING GET_CURRENT_NUMERO";
                    if(error) return "error";

                    const magazine = this.context.magazine;
                    const articles = data.currentNumero.articles;

                    let article; if(!articles) return null;
                    article = this.getArticle(articles, data.currentNumero);
                    if(!article) return null;

                    return (
                        <div ref={ this.setReference }>
                            <ArticleView
                                article={ article }
                                magazine={ magazine }
                                heightFrame={this.state.heightFrame}
                                numero={data.currentNumero}
                                scrolled={this.state.scrolled}
                                isPreview={ this.isPreview }
                                previewLoading={ this.props.previewLoading }
                            />
                            <ScrollContext.Consumer>
                                {({ changeScrollWidth, scrollWidth }) => (
                                    <ArticleContextScroll
                                        scrollWidth={ scrollWidth }
                                        user={ this.props.user }
                                        setArticleReaded={ this.props.setArticleReaded }
                                        articleIsRead={ this.articleIsRead }
                                        article={ article }
                                    />
                                )}
                            </ScrollContext.Consumer>
                        </div>
                    );
                }}
            </Query>
        )

    }
}

const mapStateToProps = (state, props) => {
    return {
        slug: props.match.params.slug,
        props: props,
        previewLoading: state.preview.previewLoading,
        widthScroll: state.scrolled.widthScroll,
        user: state.user
    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentArticle: (article, numero) => {
            dispatch({
                type: 'CHANGE_CURRENT_ARTICLE',
                currentArticle: article,
                view: "article"
            });
        },
        setArticleReaded: (article) => {
            dispatch({
                type: 'USER_SET_ARTICLE_READ',
                article,
            });
        },
        setArticleLastRead: (article, numero) => {
            dispatch({
                type: 'USER_SET_LAST_READ',
                article,
                numero
            });
        },
    };
};

Article.contextTypes = {
    magazine: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Article)));

class ArticleContextScroll extends Component {
    constructor(props){
        super(props);
    }
    render() {
        if(this.props.scrollWidth > 85){
            if(!this.props.articleIsRead(this.props.user, this.props.article))
                this.props.setArticleReaded(this.props.article);
        }
        return null;
    }
}
