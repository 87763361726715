import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import "./rgpd.scss";
import classNames from "classnames";
import Loadable from 'react-loadable';

import { SettingsContext } from "../../../Magazine/MagazineSettings";
import {Link, withRouter} from "react-router-dom";

class RGPD extends Component {

    constructor(props){
        super(props);
        this.state = {
            settings: null,
        }
    }

    componentDidMount() {

        let props = JSON.parse(JSON.stringify( this.props.settings ));

        this.setState({
            settings: props
        })
    }

    acceptAllFn = (confirm = false) => {

        this.setState(state => {

            let categories = this.state.settings.categories
                .map((category) => {
                    category.services.map((service) => {
                        service.accepted = true;
                        return service;
                    });
                    return category;
                });

            return {
                settings: {
                    ...this.state.settings,
                    categories: categories
                }
            };

        });

        if(confirm) {
            this.saveChanges();
        }

    };

    refuseAllFn = (confirm = false) => {

        this.setState(state => {

            let categories = this.state.settings.categories
                .map((category) => {
                    category.services.map((service) => {
                        service.accepted = false;
                        return service;
                    });
                    return category;
                });

            return {
                settings: {
                    ...this.state.settings,
                    categories: categories
                }
            }

        });

        if(confirm) {
            this.saveChanges();
        }

    };

    saveChanges = () => {

        this.setState({
            settings: {
                ...this.state.settings,
                interaction: true
            }
        });

        this.props.changeSettings(this.state.settings);
    };

    toggleSettingValue = (categorySlug, serviceSlug, value) => {

        this.setState(state => {

            let categories = this.state.settings.categories
                .map((category) => {
                    if(category.slug === categorySlug){
                        category.services.map((service) => {
                            if(service.slug === serviceSlug){
                                service.accepted = value;
                            }
                            return service;
                        })
                    }
                    return category;
                });

            return {
                settings: {
                    ...this.state.settings,
                    categories: categories
                }
            }
        });

    };


    render(){

        if(typeof document !== "undefined") {
            if(this.state.settings) {
                if(this.props.window){
                    return ReactDOM.createPortal(
                        <RGPDView rgpd={ this.props.rgpd }
                                  settings={this.state.settings}
                                  SwitchiOSComponent={ SwitchiOSComponent }
                                  toggleSettingValue={this.toggleSettingValue}
                                  saveChanges={ this.saveChanges }
                                  acceptAllFn={ this.acceptAllFn }
                                  refuseAllFn={ this.refuseAllFn }
                                  closeRGPDPopup={ this.props.closeRGPDPopup }
                        />,
                        document.getElementById("rgpd-portal")
                    );
                } return null;
            } return null;
        } return null;

    }

}

export class RGPDView extends Component {

    constructor(props){
        super(props);

        this.state = {
            hide: true,
            hidePopup: true,
            view: "message"
        }

    }

    hide(reverse = false){ let that = this;

        setTimeout(function() {

            setTimeout(function() {
                that.setState({
                    hide: reverse,
                });
            }, (!reverse) ? 0 : 3500);
            setTimeout(function(){
                that.setState({
                    hidePopup: reverse
                });
            }, 10);
        }, (!reverse) ? 3500 : 0);

    }

    hideWithoutWait(reverse){  let that = this;

        that.setState({
            hide: reverse,
        });

        setTimeout(function(){
            that.setState({
                hidePopup: reverse
            });
        }, 10);

    }

    closeView = () => { let that = this;

        this.hide(true);

        setTimeout(function() {
            that.props.closeRGPDPopup();
        }, 520);

    };

    rejectAll = (hide = false) => { let that = this;
        if(hide) this.hide(true);

        setTimeout(function() {
            if(hide){
                that.props.refuseAllFn(true);
            } else {
                that.props.refuseAllFn();
            }
        }, 520);

    };

    acceptAll = (hide = false) => { let that = this;
        if(hide) this.hide(true);

        setTimeout(function() {
            if(hide){
                that.props.acceptAllFn(true);
            } else {
                that.props.acceptAllFn();
            }
        }, 520);

    };

    saveChangesView = () => { let that = this;

        this.hide(true);

        setTimeout(function() {
            that.props.saveChanges();
        }, 500);
    };

    changeView = (what) => {
        this.setState({
            view: what
        })
    };

    render() {

        const classes = classNames({
            'hide': this.state.hidePopup,
            'show': !this.state.hidePopup,
            'rgpd-outer': true,
            'already-interact': this.props.rgpd.settings.interaction
        });

        if(this.state.hide) {
            if(this.props.rgpd.settings.interaction){
                this.hideWithoutWait();
                this.setState({
                    view: "settings"
                })
            } else {
                this.hide();
            }
        }

        if(!this.state.hide) {
            return (
                <SettingsContext.Consumer>
                {({ fonts }) => (
                    <div className={ classes }>
                    <div className="rgpd-inner">

                        {this.props.rgpd.settings.interaction &&
                            <button className="close" onClick={ this.closeView }>
                                close
                            </button>
                        }

                        {this.state.view === 'message' &&
                        <div className="rgpd-message">
                            <div className="header">
                                <div className="title" style={ fonts.family1 }>
                                    Réglages de confidentialité
                                </div>
                            </div>
                            <div className="main">
                                <p>
                                    Les cookies sont importants pour le bon fonctionnement d'un site. Afin d'améliorer votre expérience, nous utilisons des cookies pour conserver les informations de connexion et fournir une connexion sûre, collecter les statistiques en vue d'optimiser les fonctionnalités du site et adapter le contenu à vos centres d'intérêt. Cliquez sur Accepter et continuer pour accepter les cookies et poursuivre directement sur le site ou cliquez sur Plus d’informations pour consulter en détail les descriptions des types de cookies et choisir ceux que vous voulez accepter lorsque vous visitez le site.
                                </p>
                            </div>
                            <div className="footer">

                                <div className="accept-btns">
                                    <div className="btn accept" style={ fonts.family1 } onClick={ () => this.acceptAll(true) }>Je suis d'accord</div>
                                    <div className="btn reject" style={ fonts.family1 } onClick={ () => this.rejectAll(true) }>Je ne suis pas d'accord</div>
                                </div>

                                <div className="navigation">
                                    <button className="more" onClick={ () => this.changeView("settings") }>Plus d'informations</button>
                                </div>
                            </div>
                        </div>}

                        {this.state.view === 'settings' &&
                            <RGPDsettings changeView={ this.changeView }
                                          acceptAll={ this.acceptAll }
                                          rgpd={ this.props.rgpd }
                                          fonts={ fonts }
                                          rejectAll={ this.rejectAll }
                                          SwitchiOSComponent={ this.props.SwitchiOSComponent }
                                          settings={ this.props.settings }
                                          toggleSettingValue={ this.props.toggleSettingValue }
                                          saveChanges={ this.saveChangesView }
                            />
                        }

                    </div>
                </div>
                )}
                </SettingsContext.Consumer>
            )
        } else return null;
    }


}

export class RGPDsettingsView extends Component {

    render() { let that = this;

        return(
            <div className="rgpd-settings">
                <div className="header">
                    <div className="title" style={ this.props.fonts.family1 }>
                        Réglages de confidentialité
                    </div>
                    <div className="accept-btns">
                        <div className="btn accept" style={ this.props.fonts.family1 } onClick={ () => this.props.acceptAll() }>Je suis d'accord</div>
                        <div className="btn reject" style={ this.props.fonts.family1 } onClick={ () => this.props.rejectAll() }>Je ne suis pas d'accord</div>
                    </div>
                </div>
                <div className="main">
                    {this.props.settings.categories.map((category) => {
                        return(
                            <div className="category-rgpd">
                                <div className="title" style={ this.props.fonts.family1 }>{ category.title }</div>
                                <div className="services">
                                    {category.services.map((service) => {
                                        return <Service service={ service }
                                                        category={ category }
                                                        SwitchiOSComponent={ this.props.SwitchiOSComponent }
                                                        key={ service.id }
                                                        toggleSettingValue={ that.props.toggleSettingValue }

                                        />
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="footer">
                    <div className="navigation">
                        <button className="back" onClick={ () => this.props.changeView("message") }>Précédent</button>
                    </div>
                    <div className="btn" style={ this.props.fonts.family1 } onClick={ that.props.saveChanges }>Valider</div>
                </div>
            </div>
        )
    }

}

const loadingComponent = () => {
    return (
        <div className="loading-component" />
    )
};

const SwitchiOSComponent = Loadable({
    loader: () => import(/* webpackChunkName: "SwitchiOSComponent" */ 'react-ios-switch'),
    loading: loadingComponent,
    modules: ['SwitchiOSComponent']
});


class Service extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return(
            <div className="service">
                <div className="details">
                    <div className="title">
                        { this.props.service.title }
                    </div>
                    <div className="desc">
                        { this.props.service.desc }
                    </div>
                </div>
                <div className="activate">
                    <this.props.SwitchiOSComponent
                        checked={ this.props.service.accepted }
                        onColor="rgb(28, 46, 188)"
                        onChange={ checked => {
                            this.props.toggleSettingValue(this.props.category.slug, this.props.service.slug, checked);
                        }}
                    />
                    <span className="value">
                        { this.props.service.accepted && "on"}
                        { !this.props.service.accepted && "off"}
                    </span>
                </div>
            </div>
        )

    }

}


export class RGPDsettings extends Component {

    constructor(props){
        super(props);
    }

    render() {
        return(
            <RGPDsettingsView
                settings={ this.props.settings }
                fonts={ this.props.fonts }
                changeView={ this.props.changeView }
                SwitchiOSComponent={ this.props.SwitchiOSComponent }
                acceptAll={ this.props.acceptAll }
                rejectAll={ this.props.rejectAll }
                toggleSettingValue={ this.props.toggleSettingValue }
                saveChanges={ this.props.saveChanges }
            />
        )
    }


}

const mapStateToProps = (state, props) => {

    return {
        rgpd: state.rgpd,
        window: state.rgpd.window,
        store: state,
        settings: state.rgpd.settings
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeSettings: (settings) => {
            return dispatch({
                type: 'CHANGE_SETTINGS',
                settings: settings
            })
        },
        closeRGPDPopup: () => {
            return dispatch({
                type: 'CLOSE_WINDOW_RGPD'
            })
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RGPD));
