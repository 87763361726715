//import { TweenMax, Power3, Power2, TimelineLite, TimelineMax, AttrPlugin } from "gsap";

import GSAPInit from "../../../Animations/GSAP";

class GsapComponent {

    footerMenuEnter = (gsap, target) => {
        if(target === null || !gsap) return;
        if(!target) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.4, {
                opacity: 0,
                y: 70,
            }, {
                opacity: 1,
                y: 0,
            })
    };

    footerMenuLeave = (gsap, target) => {
        if(target === null || !gsap) return;
        if(!target) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.2, {
                opacity: 1
            }, {
                opacity: 0
            })

    };

    backgroundEnter = (gsap, target) => {

        if(target === null || !gsap) return;
        if(!target) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.4, {
                opacity: 0
            }, {
                opacity: 1
            })
    };

    backgroundLeave = (gsap, target) => {

        if(target === null || !gsap) return;
        if(!target) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.2, {
                opacity: 1
            }, {
                opacity: 0
            })
    };

    onEnter = (gsap, target) => {

        if(target === null || !gsap) return;

        const timeline = new gsap.TimelineMax();

        if(!target) return;

        timeline
            .fromTo(target, 0.4, {
                opacity: 0,
                y: 0
            }, {
                opacity: 1,
                y: 0,
                ease: gsap.Power2.easeOut,
            }, 0)
            .staggerFromTo(target.getElementsByClassName("chapitre"), 0.7, {
                opacity: 0,
                y: 110,
            }, {
                opacity: 1,
                y: 0,
                ease: gsap.Power2.easeOut,
            }, 0.2, "-=0.2");
    };

    onLeave = (gsap, target) => { if(target === null || !gsap) return;
        const timeline = new gsap.TimelineMax();
        timeline
            .fromTo(target, 0.05, {
                opacity: 1,
                y: 0,
            }, {
                opacity: 0,
                y: 0,
                ease: gsap.Power3.easeOut,
            });
    };
    fixWidth = (gsap, target, width) => { if(target === null || !gsap) return;
        const timeline = new gsap.TimelineMax();
        if(!target) return;

        timeline
            .to(target.getElementsByClassName("article-li"), 0, {
                width: width,
            });

    };

}

let gsap = new GsapComponent();
let gsapInit = new GSAPInit();

export default {
    footerMenuEnter: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.footerMenuEnter(module, target)
        });
    },
    footerMenuLeave: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.footerMenuLeave(module, target)
        });
    },
    backgroundEnter: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.backgroundEnter(module, target)
        });
    },
    backgroundLeave: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.backgroundLeave(module, target)
        });
    },
    fixWidth: (target, width) => {
        gsapInit.importGSAP().then((module) => {
            gsap.fixWidth(module, target, width)
        })
    },
    onEnter: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.onEnter(module, target)
        });
    },
    onLeave: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.onLeave(module, target)
        });
    },
};


/*
export default {
    footerMenuEnter: (target) => {
        if(target === null) return;

        const timeline = new TimelineMax();

        timeline
            .fromTo(target, 0.4, {
                opacity: 0,
                y: 70,
            }, {
                opacity: 1,
                y: 0,
            })
    },
    footerMenuLeave: (target) => {
        if(target === null) return;

        const timeline = new TimelineMax();

        timeline
            .fromTo(target, 0.2, {
                opacity: 1
            }, {
                opacity: 0
            })

    },
    backgroundEnter: (target) => {

        if(target === null) return;

        const timeline = new TimelineMax();

        timeline
            .fromTo(target, 0.4, {
                opacity: 0
            }, {
                opacity: 1
            })
    },
    backgroundLeave: (target) => {

        if(target === null) return;

        const timeline = new TimelineMax();

        timeline
            .fromTo(target, 0.2, {
                opacity: 1
            }, {
                opacity: 0
            })
    },
    onEnter: (target) => {

        if(target === null) return;

        const timeline = new TimelineMax();

        timeline
            .staggerFromTo(target.getElementsByClassName("chapitre"), 0.7, {
                opacity: 0,
                y: 110,
            }, {
                opacity: 1,
                y: 0,
                ease: Power2.easeOut,
            }, 0.2);
    },
    onLeave: (target) => { if(target === null) return;
        const timeline = new TimelineMax();
        timeline
            .fromTo(target, 0.05, {
                opacity: 1,
                y: 0,
            }, {
                opacity: 0,
                y: 60,
                ease: Power3.easeOut,
            });
    },
};
*/
